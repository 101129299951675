let intialState = {
    sideMenuList: [],
    isSuggestion: false
}

export default function sideBarReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case 'Get_Side_Menu_List':
            stateCopy.sideMenuList = action.payload.map(item => ({ ...item, id: item.action }));;
            return stateCopy;
        case 'Get_Student_Details':
            stateCopy.studentDetails = action.payload;
            localStorage.setItem('center', action.payload.center_id)
            return stateCopy;
        case 'Get_Popup_Notifications':
            stateCopy.notifications = action.payload.data;
            stateCopy.unread = action.payload.unread;
            return stateCopy;
        case 'Marked_Notification':
            window.location.href = action.payload;
            return stateCopy;
        case 'Get_Profile_Details':
            stateCopy.profileDetails = action.payload;
            localStorage.setItem('id', stateCopy.profileDetails._id);
            localStorage.setItem('role', stateCopy.profileDetails?.role);
            return stateCopy;
        case 'Intro_Complete':
            localStorage.setItem("introComplete", JSON.stringify(true));
            return stateCopy;
        case 'Get_Suggestions':
            stateCopy.suggestions = action.payload;
            return stateCopy;
        case 'Open_Suggestion':
            stateCopy.isSuggestion = true;
            return stateCopy;
        case 'Close_Suggestion':
            stateCopy.isSuggestion = false;
            return stateCopy;
        case 'Logout_Done':
            return stateCopy
        default:
            return stateCopy;

    }
}