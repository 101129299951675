let intialState = {
    isLoading: true,
    batchAttendance: null,
    batches: []
}

export default function manageAttendanceReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case 'Get_Batch_Programs':
            stateCopy.batchPrograms = action.payload.filter(el => el.type === 'batch').map(el => [{ value: el._id, label: el.program, type: el.type }][0]);
            return stateCopy;
        case 'Get_Batch_From_Programs':
            stateCopy.batches = action.payload;
            if (!stateCopy.batches.length) {
                stateCopy.isLoading = false;
            }
            return stateCopy;
        case 'Get_Batch_Attendance':
            stateCopy.batchAttendance = action.payload;
            stateCopy.isLoading = false;
            return stateCopy;
        case 'Reset_Batches':
            stateCopy.batches = [];
            stateCopy.batchAttendance = [];
            return stateCopy;
        case 'Reset_Attendance_List':
            stateCopy.batchAttendance = null;
            stateCopy.isLoading = true;
            return stateCopy;
        case 'Get_Batch_Student_Attendance':
            stateCopy.batchStudentAttendance = action.payload;
            stateCopy.batchStudentAttendanceCopy = action.payload;
            stateCopy.present = stateCopy.batchStudentAttendance.filter(el => el.present).length;
            return stateCopy;
        case 'Mark_Present':
            stateCopy.batchStudentAttendance.forEach(element => {
                element.present = true
            });
            stateCopy.present = stateCopy.batchStudentAttendance.filter(el => el.present).length;
            return stateCopy;
        case 'Mark_Absent':
            stateCopy.batchStudentAttendance.forEach(element => {
                element.present = false
            });
            stateCopy.present = stateCopy.batchStudentAttendance.filter(el => el.present).length;
            return stateCopy;
        case 'Search_Student':
            if (action.payload === '') {
                stateCopy.batchStudentAttendance = stateCopy.batchStudentAttendanceCopy;
            }
            else {
                const filterResult = stateCopy.batchStudentAttendanceCopy.filter(el => { return el.firstName.toLowerCase().includes(action.payload.toLowerCase()) || el.lastName.toLowerCase().includes(action.payload.toLowerCase()) })
                stateCopy.batchStudentAttendance = filterResult;
            }
            return stateCopy;
        case 'Mark_As_Individual':
            stateCopy.batchStudentAttendance.forEach(el => {
                if (el.student_id === action.payload.id) {
                    el.present = el.present ? false : true
                    el.leave = el.present && null
                }
            })
            stateCopy.present = stateCopy.batchStudentAttendance.filter(el => el.present).length;
            return stateCopy;
        case 'Change_Leave_Status':
            stateCopy.batchStudentAttendance.forEach(el => {
                if (el.student_id === action.payload.id) {
                    el.leave = action.payload.val
                }
            })
            return stateCopy;

        default:
            return stateCopy;

    }
}