let intialState = {
    tests: [],
    subject: null,
    isQuiz: false,
    quizDetails: {},
    testProgress: [],
    testList: {},
    testLoading: true,
    isListLoading: false
}

export default function testReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case "Get_All_Program":
            stateCopy.programs = localStorage.getItem('role') === 'student' ? action.payload.filter(el => el.type === 'default') : localStorage.getItem('role') === 'trainee' ? action.payload.filter(el => el.type === 'batch') : action.payload.filter(el => el.type === 'corporate');
            var len = action.payload[0].semester;
            stateCopy.semesters = getSemesterArr(len);
            return stateCopy;
        case "Get_suggestion":
            stateCopy.tests = action.payload;
            stateCopy.isListLoading = true;
            return stateCopy;
        case "Get_Sem":
            const total = stateCopy.programs.filter(el => el._id === action.payload._id)[0].semester;
            stateCopy.semesters = getSemesterArr(total);
            return stateCopy;
        case "Get_Subject_Test_Progress":
            stateCopy.testProgress = action.payload;
            stateCopy.testLoading = false
            if (!action.payload.length) {
                stateCopy.isListLoading = false;
            }
            stateCopy.subject = action.payload.length ? action.payload[0] : { _id: '', title: '' };
            return stateCopy;
        case "Reset_Loader":
            stateCopy.isListLoading = true;
            return stateCopy;
        case "Get_Test_List":
            stateCopy.testList = action.payload;
            stateCopy.data = action.payload.data;
            stateCopy.isListLoading = false;
            return stateCopy;
        case "Open_Quiz":
            stateCopy.quizDetails = action.payload.data;
            stateCopy.questions = action.payload.data.questions;
            stateCopy.isQuiz = true;
            if (action.payload.attempts > 0) {
                stateCopy.questions.forEach((object) => {
                    object.answered = null;
                    object.correct_id = null;
                    object.correct = null;
                });
                stateCopy.question = stateCopy.questions[0];
            }
            return stateCopy;
        case 'Get_Question':
            stateCopy.question = stateCopy.questions.filter(el => el._id === action.payload._id)[0];
            return stateCopy;
        case "Get_Test_Answers":
            stateCopy.question.correct = action.payload.data.correct;
            stateCopy.question.correct_id = action.payload.data.correct_id;
            stateCopy.question.answered = action.payload.aid;
            const answersns = stateCopy.questions.map(answer => {
                if (answer._id === action.payload.qid) return {
                    _id: answer._id,
                    title: answer.title,
                    description: answer.description,
                    type: answer.type,
                    skill: answer.skill,
                    liked: answer.liked,
                    answers: answer.answers,
                    correct_id: action.payload.data.correct_id,
                    correct: action.payload.data.correct,
                    answered: action.payload.aid
                }
                return answer
            })
            stateCopy.questions = answersns;
            return stateCopy;
        case "Test_Save_Score":
            stateCopy.testList = action.payload;
            return stateCopy;
        case "Retake_Test":
            stateCopy.questions.forEach((object) => {
                object.answered = null;
                object.correct_id = null;
                object.correct = null;
            });
            stateCopy.question = stateCopy.questions[0];
            return stateCopy;
        case "Check_Pending":
            if (action.payload) {
                const check = stateCopy.testList.data.filter(el => el.attempts === 0);
                stateCopy.testList.data = check;
            }
            else {
                stateCopy.testList.data = stateCopy.data;
            }
            return stateCopy;
        case "Reset_Quiz_Popup":
            stateCopy.isQuiz = false;
            stateCopy.questions.forEach((object) => {
                object.answered = null;
                object.correct_id = null;
                object.correct = null;
            });
            stateCopy.question = stateCopy.questions[0];
            return stateCopy;
        case "Closed_Quiz":
            stateCopy.isQuiz = false;
            return stateCopy;
        case "Get_Subject_Test_Progress1":
            stateCopy.testProgress = action.payload.data;
            stateCopy.subject = action.payload.data.filter(el => el.code === action.payload.code)[0];
            stateCopy.isListLoading = false;
            return stateCopy;
        default:
            return stateCopy;
    }
}

const getSemesterArr = (len) => {
    var arr = [];
    for (var i = 0; i < len; i++) {
        arr.push({
            name: 'Semester ' + (i + 1),
            code: i + 1
        });
    }
    return arr;
}