let intialState = {
    feedList: [],
    isLoading: true,
    isFeed: false,
    subjectFilter: [],
    contentFilter: [],
    noResults: false,
    resource: { note: null }
}



export default function feedsReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case "Get_All_Feeds":
            if (action.payload.length > 0) {
                stateCopy.feedList = action.payload;
                stateCopy.isLoading = false;
                stateCopy.noResults = false;
            }
            else {
                stateCopy.noResults = true;
                stateCopy.isLoading = false;
            }

            return stateCopy;
        case 'Get_Feed_Topics':
            stateCopy.topics = action.payload;
            const content = action.payload.map(el => [el.topics.map(it => ({ ...it, image: el.image }))][0]);
            stateCopy.onlyTopics = [].concat.apply([], content);
            return stateCopy;
        case "Get_Subject_filter":
            stateCopy.subjectFilter = action.payload.subject;
            stateCopy.contentFilter = action.payload.type;
            return stateCopy;
        case "Reset_IsLoding":
            stateCopy.isLoading = true;
            return stateCopy;
        case "Type_Fliter_Click":
            stateCopy.isLoading = true;
            stateCopy.noResults = false;
            stateCopy.contentFilter = stateCopy.contentFilter.map(answer => {
                if (answer.title === action.payload.title) return { value: !action.payload.value, title: answer.title, _id: answer._id }
                return answer
            });
            return stateCopy;
        case "Subject_Fliter_Click":
            stateCopy.isLoading = true;
            stateCopy.noResults = false;
            stateCopy.subjectFilter = stateCopy.subjectFilter.map(answer => {
                if (answer.title === action.payload.title) return { value: !action.payload.value, title: answer.title, _id: answer._id }
                return answer
            });
            return stateCopy;
        case "Get_Next_Feeds":
            if (action.payload.length > 0) {
                stateCopy.feedList = stateCopy.feedList.concat(action.payload);
                stateCopy.isLoading = false;
            }
            else {
                // stateCopy.feedList = stateCopy.feedList.concat(action.payload);
                stateCopy.isLoading = false;
            }

            return stateCopy
        case "Get_Feed_Resource":
            stateCopy.isFeed = true;
            stateCopy.resource = (window.location.pathname.split('/')[1] === 'skill-pods') ? action.payload : action.payload.items;
            if (window.location.pathname.split('/')[1] !== 'skill-pods' && window.location.pathname.split('/')[1] !== 'attachment') {
                if (action.payload.data !== null) {
                    stateCopy.resource.note = action.payload.data.note;
                }
                else {
                    stateCopy.resource.note = null;
                }
            }
            return stateCopy;
        case 'Get_Content_Question1':
            stateCopy.isFeed = true;
            stateCopy.resource = { ...action.payload, type1: 'Question' };
            return stateCopy;
        case 'Get_Answer_In_Feeds':
            stateCopy.resource.correct = action.payload.data.correct;
            stateCopy.resource.correct_id = action.payload.data.correct_id;
            stateCopy.resource.answered = action.payload.aid;
            return stateCopy;
        case "Analytic_Feed_Capture":
            return stateCopy;
        case "Type_note":
            stateCopy.resource.note = action.payload;
            return stateCopy;
        case "Get_Feed_Like":
            const feeds = stateCopy.feedList.map(answer => {
                if (answer._id === action.payload.id)
                    return {
                        _id: answer._id,
                        code: answer.code,
                        duration: answer.duration,
                        description: answer.description,
                        image: answer.image,
                        liked: !action.payload.liked,
                        likedCount: answer.likedCount,
                        pod_id: answer.pod_id,
                        source: answer.source,
                        source_image: answer.source_image,
                        subject: answer.subject,
                        note: stateCopy.resource.note,
                        title: answer.title,
                        url: answer.url,
                        type: answer.type,
                        viewCount: answer.viewCount
                    }
                return answer
            })
            stateCopy.feedList = feeds;
            if (stateCopy.resource !== {}) {
                stateCopy.resource.liked = !action.payload.liked;
            }
            return stateCopy;
        case "Close_Feed":
            stateCopy.isFeed = false;
            stateCopy.resource = {};
            return stateCopy;
        case 'Share_Popup_Open':
            stateCopy.isShareFeedPopup = true;
            stateCopy.shareObj = action.payload;
            return stateCopy;
        case 'Share_Popup_Close':
            stateCopy.isShareFeedPopup = false;
            stateCopy.shareObj = {};
            return stateCopy;
        case 'Share_Done':
            stateCopy.isShareFeedPopup = false;
            stateCopy.shareObj = {};
            return stateCopy;
        default:
            return stateCopy;
    }
}