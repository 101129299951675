const intialState = {
    sectionAchievers: [],
    programAchievers: [],
    centerAchievers: [],
    monthly: [],
    subjectWise: [],
    isListLoading: true,
    isCardLoading: true,
    complete: true
};

export default function achieverReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case 'Get_Section_Achievers':
            stateCopy.sectionAchievers = action.payload;
            stateCopy.isListLoading = false;
            stateCopy.isCardLoading = false;
            return stateCopy;
        case 'Get_Program_Achievers':
            stateCopy.programAchievers = action.payload;
            stateCopy.isListLoading = false;
            stateCopy.isCardLoading = false;
            return stateCopy;
        case 'Get_Center_Achievers':
            stateCopy.centerAchievers = action.payload;
            stateCopy.isListLoading = false;
            stateCopy.isCardLoading = false;
            stateCopy.complete = false;
            return stateCopy;
        case 'Loader_Active':
            stateCopy.isListLoading = true;
            stateCopy.isCardLoading = true;
            return stateCopy;
        case 'Apperciation_Sent':
            if (action.payload.obj.type === 'section') {
                stateCopy.sectionAchievers.forEach(el => {
                    if (el.email === action.payload.obj.to) {
                        el.appreciation = true
                    }
                })
            }
            else if (action.payload.obj.type === 'program') {
                stateCopy.programAchievers.forEach(el => {
                    if (el.email === action.payload.obj.to) {
                        el.appreciation = true
                    }
                })
            }
            else {
                stateCopy.centerAchievers.forEach(el => {
                    if (el.email === action.payload.obj.to) {
                        el.appreciation = true
                    }
                })
            }
            return stateCopy;
        case 'Get_Monthly_Data':
            stateCopy.months = action.payload.months;
            stateCopy.consumed = action.payload.data;
            return stateCopy;
        case 'Get_Subjects_Data':
            stateCopy.subject = action.payload.subjects;
            stateCopy.contentCounts = action.payload.data;
            return stateCopy;
        default:
            return stateCopy
    }
}