let intialState1 = {
    isAuthorized: false,
    user: localStorage.getItem('profileDetails'),
    succesMessage: false
}

export default function loginReducer(state = intialState1, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case "Login_user":
            stateCopy.isAuthorized = true;
            localStorage.setItem('loginToken', action.payload.token);
            localStorage.setItem('loginEmail', action.payload.email);
            localStorage.setItem('name', action.payload.name);
            localStorage.setItem('profileDetails', JSON.stringify(action.payload));
            localStorage.setItem('role', action.payload.role);
            localStorage.setItem('introComplete', JSON.stringify(action.payload.introComplete))
            stateCopy.user = action.payload;
            window.location.reload();
            return stateCopy;
        case "Login_Error":
            stateCopy.succesMessage = true;
            stateCopy.succesMessageText = action.payload;
            return stateCopy;
        case "Accept_Invite":
            stateCopy.isAuthorized = true;
            stateCopy.user = action.payload;
            localStorage.setItem('loginToken', action.payload.token);
            localStorage.setItem('loginEmail', action.payload.email);
            localStorage.setItem('name', action.payload.name);
            localStorage.setItem('profileDetails', JSON.stringify(action.payload));
            localStorage.setItem('role', action.payload.role);
            localStorage.setItem('introComplete', JSON.stringify(action.payload.introComplete))
            window.location.reload();
            return stateCopy;
        case 'Accept_Invite_Failed':
            stateCopy.succesMessage = true;
            stateCopy.succesMessageText = action.payload;
            return stateCopy;
        case 'Logout_Done':
            stateCopy.user = null;
            return stateCopy;
        default:
            return stateCopy;
    }
}
